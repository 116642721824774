package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.lib.db.entities.xapi.XapiSessionEntity
import kotlin.Boolean
import kotlin.Long

public actual abstract class XapiSessionEntityDao {
  public actual abstract suspend fun insertAsync(xapiSessionEntity: XapiSessionEntity)

  public actual abstract suspend fun findByUidAsync(uid: Long): XapiSessionEntity?

  public actual abstract suspend fun updateLatestAsComplete(
    completed: Boolean,
    time: Long,
    xseUid: Long,
  )

  public actual abstract suspend fun findMostRecentSessionByActorAndActivity(
    accountPersonUid: Long,
    actorUid: Long,
    xseRootActivityUid: Long,
  ): XapiSessionEntity?
}
