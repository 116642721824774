package com.ustadmobile.core.db.dao.xapi

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.xapi.XapiSessionEntity
import kotlin.Boolean
import kotlin.Long

public class XapiSessionEntityDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: XapiSessionEntityDao,
) : XapiSessionEntityDao() {
  override suspend fun insertAsync(xapiSessionEntity: XapiSessionEntity) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(xapiSessionEntity.xseUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(400122)
      xapiSessionEntity.xseUid = _newPk
    }
    xapiSessionEntity.xseLastMod = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(xapiSessionEntity)
  }

  override suspend fun findByUidAsync(uid: Long): XapiSessionEntity? = _dao.findByUidAsync(uid)

  override suspend fun updateLatestAsComplete(
    completed: Boolean,
    time: Long,
    xseUid: Long,
  ) {
    _dao.updateLatestAsComplete(completed, time, xseUid)
  }

  override suspend fun findMostRecentSessionByActorAndActivity(
    accountPersonUid: Long,
    actorUid: Long,
    xseRootActivityUid: Long,
  ): XapiSessionEntity? = _dao.findMostRecentSessionByActorAndActivity(accountPersonUid, actorUid,
      xseRootActivityUid)
}
